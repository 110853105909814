import { Expose, Transform, Type } from 'class-transformer';
import { ImageDto } from '../../../types/image.dto';

export class ProductDto {
  @Expose()
  uuid!: number;

  @Expose()
  name!: string;

  @Expose()
  longDescription?: string;

  @Expose()
  shortDescription?: string;

  @Expose()
  price?: number;

  @Expose()
  mutedPrice?: number;

  @Expose()
  @Transform(({obj: product}: { obj: ProductDto }) => {
    const price = Number(product.price);
    if (isNaN(price)) {
      return '';
    }

    return new Intl.NumberFormat('us-US',
      {style: 'currency', currency: 'USD'}).format(price);
  })
  priceFormatted?: string;

  @Expose()
  @Transform(({obj: product}: { obj: ProductDto }) => {
    const mutedPrice = Number(product.mutedPrice);
    if (isNaN(mutedPrice)) {
      return '';
    }
    return new Intl.NumberFormat('us-US',
      {style: 'currency', currency: 'USD'}).format(mutedPrice);
  })
  mutedPriceFormatted?: string;

  @Expose()
  @Type(() => ImageDto)
  images!: ImageDto;
}
