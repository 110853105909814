import React, { useContext, useEffect, useState } from 'react';
import { IProps } from '../../../types';
import { ProductDto } from '../dto/product-dto';
import { TrySync } from '../../../utils';
import { CartUtils } from '../../cart/cart-utils';
import { AppContext, IAppContext } from '../../../context';
import Spinner from '../../spinner/spinner';

interface IProductItem extends IProps {
  product?: ProductDto;
}

const HomeProductItem = ({product}: IProductItem) => {
  const {cart, setCart} = useContext(AppContext) as IAppContext;
  const [submitItemToCart, setSubmitItemToCart] = useState(false);
  const [productImage, setProductImage] = useState('');

  useEffect(() => {
    setProductImage(
      product?.images?.main ?? 'https://dummyimage.com/650x500/dee2e6/6c757d.jpg');
  }, [product]);

  const onAddToCart = () => {
    setSubmitItemToCart(true);
    CartUtils.addToCart(cart, product, 1, setCart).finally(() => {
      setSubmitItemToCart(false);
    });
  };

  return (
    <div className="col mb-5">
      <div className="card h-100">
        <div
          className="badge bg-dark text-white position-absolute top-05rem right-05rem">Sale
        </div>
        <img className="card-img-top w-100"
             src={productImage}
             alt="..."/>
        <div className="card-body p-4">
          <div className="text-center">
            <h5 className="fw-bolder">{product?.name ?? ''}</h5>
            <div
              className="d-flex justify-content-center small text-warning mb-2">
              <div className="bi-star-fill"></div>
              <div className="bi-star-fill"></div>
              <div className="bi-star-fill"></div>
              <div className="bi-star-fill"></div>
              <div className="bi-star-fill"></div>
            </div>
            {TrySync(() => {
              if (typeof product?.mutedPrice !== 'number') {
                return <></>;
              }
              return <span
                className="text-muted text-decoration-line-through">{product?.mutedPriceFormatted}</span>;
            })}
            {TrySync(() => {
              return product?.priceFormatted;
            })}
          </div>
        </div>
        <div
          className="card-footer p-4 pt-0 border-top-0 bg-transparent">
          <div className="text-center">
            <button
              type={'button'}
              className="btn btn-outline-dark mt-auto"
              onClick={onAddToCart}
              disabled={submitItemToCart}
            >
              <Spinner show={submitItemToCart}/>
              {
                TrySync(() => {
                  if (!submitItemToCart) {
                    return 'Add to cart';
                  }
                  return <></>;
                })
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProductItem;
