import { StoreCmsPropertiesDto } from '../context/dto';

export class CmsUtils {
  static propertyValue(
    data: StoreCmsPropertiesDto, propertyName: string): string | json {
    if (!data.properties || data.properties.length === 0) {
      return '';
    }
    const cmsProperty = data.properties.find(
      property => property.property === propertyName);
    return cmsProperty?.value ?? '';
  }
}
